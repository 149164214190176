<template>
  <div class="p-[20px]">
    <!--  fixed left-[50%] translate-x-[-50%] -->
    <div class="bg-[#fff] text-center text-[23px] leading-[29px] text-[#363638] font-medium">
      Select Netword
    </div>
    <div class="mt-[20px]">
      <div class="mt-[10px] p-[20px] rounded-[10px] cursor-pointer" style="border: 1px solid #0E0E33;"
        v-for="(networkItem, networkIndex) in networkList" :key="networkIndex" @click="handleChoose(networkItem)">
        <div class="text-[16px] leading-[19px] text-[#26272B] font-medium">
          {{ `${networkItem.chainSymbol}（${networkItem.name}）` }}
        </div>
        <!-- <div class="mt-[6px] text-[14px] leading-[16px] text-[#A9A5BC] font-medium">
          {{ networkItem.handlingFee }}
        </div> -->
        <div class="mt-[6px] text-[14px] leading-[16px] text-[#A9A5BC] font-medium">
          Minimum withdrawal amount of {{ networkItem.minRechargeAmount }} {{ networkItem.symbol }}
        </div>
        <!-- <div class="mt-[6px] text-[14px] leading-[16px] text-[#A9A5BC] font-medium">
          {{ networkItem.deliveryTime }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { assetsApi } from "@/utils/api";

export default {
  name: "SelectNetwork",
  props: {
    symbol: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      networkList: [
        // {
        //   tron: "Tron（TRC20）",
        //   handlingFee: "0.01 USDT (~$0.999999)",
        //   minimumWithdrawalAmount: "10 USDT",
        //   deliveryTime: "2 minutes"
        // },
      ],
    };
  },
  created() {
    this.getUserAsset();
  },
  methods: {
    // 链列表
    getUserAsset() {
      assetsApi.fetchUserAsset(this.symbol).then((res) => {
        this.networkList = res.data
        // , ...res.data.map(item => {
        // assetsApi.fetchWithdrawalsFee({ chainSymbol: item.chainSymbol, coinSymbol: item.symbol }).then(res2 => {
        //   return res2.data
        // })
        // })
        console.log('this.networkList', this.networkList);
      });
    },
    // 选择币种
    handleChoose(networkItem) {
      // console.log('item, index', item, index);
      // let selectType = ""
      // // 模拟选择成功
      // if (index < 3) {
      //   selectType = "token"
      // } else if (index < 6) {  // 暂无的弹出
      //   selectType = "notSupported"
      // } else if (index < 9) { // 选择不支持
      //   selectType = "maintaining"
      // } else { // 选择失败
      //   this.$router.push({ path: "/selectNetworkFailed" })
      // }
      this.$emit("handleSelectNetword", networkItem)
    },
  },
};
</script>

<style lang="scss" scoped></style>
