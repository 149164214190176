import zhHans from './zh-Hans.json'
import zhHant from './zh-Hant.json'
import enUS from './en-US.json'
import jaJP from './ja-JP.json'
import koKR from './ko-KR.json'
export default {
	'zh-Hans': zhHans,
	'zh-Hant': zhHant,
	'en-US': enUS,
	'ja-JP': jaJP,
	'ko-KR': koKR,
}
