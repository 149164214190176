<template>
  <div>
    <!--  v-if="!isFullscreen || !isShowAssetsHeader" -->
    <header-c
      ref="header"
      @handleLoading="handleLoading"
      @handleLogin="handleLogin"
      @handleDeposit="handleDeposit"
    />
    <div :style="'padding-top:' + marginTopHeight + 'px'">
      <assets-header v-if="isShowAssetsHeader" />
      <router-view :key="key" @handleLogin="handleLogin" />
    </div>
    <tab v-if="isShowTab" />

    <login-dialog
      :isShowLogin.sync="isShowLogin"
      :propTitle="title"
      v-if="isShowLogin"
    />

    <drawer :isShowDrawer.sync="isShowDrawer" v-if="isShowDrawer">
      <div v-if="selectType === 'token'">
        <select-token @handleSelectToken="handleSelectToken" />
      </div>
      <div v-else-if="selectType === 'network'">
        <select-network
          :symbol="symbol"
          @handleSelectNetword="handleSelectNetword"
        />
      </div>
      <!-- <div v-else-if="type === 3" @click.stop>
        <withdraw :isDrawer="true" @handleClose="handleClose" />
      </div> -->
    </drawer>
  </div>
</template>

<script>
import HeaderC from "@/components/Header/Header.vue";
import AssetsHeader from "@/components/AssetsHeader/AssetsHeader.vue";
import LoginDialog from "@/components/LoginDialog/LoginDialog.vue";
import Drawer from "@/components/Drawer/Drawer.vue";
import SelectNetwork from "@/views/selectNetwork";
import SelectToken from "@/views/selectToken";

export default {
  name: "Home",
  components: {
    HeaderC,
    AssetsHeader,
    LoginDialog,
    Drawer,
    SelectNetwork,
    SelectToken,
  },
  data() {
    return {
      isShowLogin: false,
      title: "Log In",

      marginTopHeight: null,

      isFullscreen: false,

      isShowDrawer: false,
      selectType: "token",
      symbol: "",
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
    isShowTab() {
      const keyList = ["/homeList", "/activity", "/personal"];
      console.log("this.key", this.key, keyList.includes(this.key));
      return keyList.includes(this.key);
    },
    isShowAssetsHeader() {
      const keyList = [
        "/signIn",
        "/assets",
        "/selectNetwork",
        "/selectNetworkFailed",
        "/rechargeSuccess",
        "/assetsRechargeQr",
      ];
      console.log(
        "isShowAssetsHeader",
        keyList.includes(this.key),
        this.marginTopHeight
      );
      return keyList.includes(this.key);
    },
  },
  watch: {
    // isShowAssetsHeader(newValue) {
    //     setTimeout(() => {
    //         this.marginTopHeight = newValue ? 0 : this.getMarginTopHeight()
    //     }, 0);
    // },
  },
  mounted() {
    // 检测翻译栏是否加载
    // const observer = new MutationObserver(() => {
    //     this.adjustPaddingForTranslateBar();
    // });
    // console.log('observer', observer - 38);
    // // 观察整个document.body，以便检测翻译栏的出现
    // observer.observe(document.body, { childList: true, subtree: true });

    console.log("isFullscreen", this.isFullscreen);
    this.updateScreenResolution();
    window.addEventListener("resize", this.updateScreenResolution);
  },
  created() {
    this.$route.query.inviteCode && localStorage.setItem("inviteCode", this.$route.query.inviteCode)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenResolution);
  },
  methods: {
    updateScreenResolution() {
      this.isFullscreen = window.innerWidth <= 1024;
    },
    getMarginTopHeight() {
      return this.$refs.header && this.$refs.header.$el.offsetHeight;
    },
    handleLoading() {
      setTimeout(() => {
        this.marginTopHeight = this.getMarginTopHeight();
      }, 0);
    },
    adjustPaddingForTranslateBar() {
      const translateBar = document.querySelector(".VIpgJd-ZVi9od-ORHb");
      if (translateBar) {
        const translateBarHeight = translateBar.offsetHeight;
        document.body.style.paddingTop = `${translateBarHeight}px`;
      }
    },

    // 显示登录
    handleLogin(title) {
      this.title = title;
      this.isShowLogin = true;
    },

    // 选择币种
    handleDeposit() {
      this.isShowDrawer = true;
      this.selectType = "token";
    },
    // 监听选择的币种
    handleSelectToken(tokenItem) {
      // 选择网络成功
      this.selectType = "network";
      this.symbol = tokenItem.symbol;
      if (this.selectType === "network") {
        setTimeout(() => {
          this.isShowDrawer = true;
        }, 0);
      }
    },
    // 选择的链
    handleSelectNetword(networkItem) {
      this.$router.push({
        path: "/recharge",
        query: {
          chainSymbol: networkItem.chainSymbol,
          symbol: networkItem.symbol,
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
