<template>
    <div id="google_translate_element" v-if="false"></div>
</template>

<script>
export default {
    name: 'GoogleTranslate',
    mounted() {
        this.loadGoogleTranslateScript();
    },
    methods: {
        loadGoogleTranslateScript() {
            const script = document.createElement('script');
            // script.src = '@/utils/google-translate.js';
            script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            script.type = 'text/javascript';
            document.head.appendChild(script);

            window.googleTranslateElementInit = () => {
                new google.translate.TranslateElement({
                    pageLanguage: 'en',
                    includedLanguages: 'ar,de,en,es,fr,hu,it,ja,ko,nl,pt,ru,th,tr,vi,zh-TW,zh-CN',
                    autoDisplay: false
                }, 'google_translate_element');

                // Optional: If using jQuery for styling
                // if (typeof $ !== 'undefined') {
                //     $(window).on('load', function () {
                //         $.when(() => googleTranslateElementInit()).done(function () {
                //             $(".goog-te-combo").attr("style", "font-size: 30px");
                //             $(".goog-te-gadget-simple").attr("style", "font-size: 30px");
                //         });
                //     });
                // }
                // window.addEventListener('load', function () {
                //     // 假设 googleTranslateElementInit 是一个返回 Promise 的函数
                //     googleTranslateElementInit().then(function () {
                //         // 使用 querySelectorAll 选择元素
                //         var comboElements = document.querySelectorAll(".goog-te-combo");
                //         var gadgetSimpleElements = document.querySelectorAll(".goog-te-gadget-simple");

                //         // 遍历所有选中的元素并修改样式
                //         comboElements.forEach(function (element) {
                //             element.style.fontSize = "30px";
                //         });

                //         gadgetSimpleElements.forEach(function (element) {
                //             element.style.fontSize = "30px";
                //         });
                //     }).catch(function (error) {
                //         console.error('An error occurred:', error);
                //     });
                // });
            };
        }
    }
};
</script>

<style scoped>
/* Add any additional styling if needed */
#google_translate_element {
    position: fixed;
    top: 100px;
    right: 15px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #FFFFFF;
    z-index: 999;
    box-shadow: 0px 2px 4px 0px rgba(0, 122, 255, 0.08);
    border: 1px solid #0E0E33;
}
</style>