<template>
    <back-header class="bg-[#ffffff] px-[20px] pt-[20px] h5">
        <div class="flex items-center">
            <img class="h-[28px] w-[28px] cursor-pointer" src="@/assets/images/icon/icon72.svg" @click="handlePath({ routerUrl: '/assets' })" />
            <img class="h-[28px] w-[28px] ml-[20px] cursor-pointer" src="@/assets/images/icon/setting.svg"
                @click="handlePath({ routerUrl: '/setUp' })" />
        </div>
    </back-header>
</template>

<script>
export default {
    name: "AssetsHeader",
    props: {
        isModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
        };
    },
    mounted() { },
    methods: {
        handlePath(item) {
            this.$router.push({
                path: item.routerUrl,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
i {
    margin-bottom: 10px;
}
</style>