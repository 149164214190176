<template>
  <div id="app">
    <!-- <Home /> -->
    <GoogleTranslate />
    <router-view />
    <!-- {{ $t('add-proxy.proxy-name') }} -->
  </div>
</template>

<script>
// import Home from "./views/Home.vue";
import GoogleTranslate from './components/GoogleTranslate/GoogleTranslate.vue';
export default {
  name: "App",
  components: {
    // Home,
    GoogleTranslate
  },
};
</script>

<style lang="scss">
@import "~/node_modules/element-ui/lib/theme-chalk/index.css";
@import "~@/assets/css/variable.scss";

@font-face {
  font-family: 'Quicksand';
  src: url('./assets/fonts/PingFang SC.ttf');
}
@font-face {
  font-family: 'PingFang SC';
  src: url('./assets/fonts/PingFang Regular.ttf');
}

// .el-skeleton__p, .el-skeleton__text, .el-skeleton__image {
//     background: red !important;
// }

.el-skeleton.is-animated .el-skeleton__item {
    background-image: linear-gradient(90deg, rgb(14, 70, 88) 25%, rgb(20, 135, 211) 37%, rgb(8, 138, 182) 63%) !important;
    // background-image: #303045 !important;
    // background-color: initial;
    // animation: el-skeleton-loading 1.4s ease infinite
}

// @keyframes el-skeleton-loading {
//   0% {
//       background-position: 100% 50%;
//   }
//   100% {
//       background-position: 0 50%;
//   }
// }


.myFont {
  font-family: 'Quicksand' !important;
}

.Shares .arrow-down {
  transform: rotate(180deg);
}

.InShares .arrow-down {
  transform: rotate(0deg);
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  object-fit: cover;
}

#app {

  --first-title-color:#ECE6F8;
  // --second-title-color:#7541EA;
  // --third-title-color:#ECE6F8;
  --footer-text-color:#a9a5bc;
  --footer-text2-color:#dbd5e7;
  --green-theme-color:#70d6a8;
  --big-background-color:#010109;

  // font-family: PingFang SC, PingFang SC;
  font-family: "PingFang SC";
  font-style: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  width: 100%;
  color: #1c1c1c;
  background-color: #010109;
  // background-color: #0E0E33;
  min-height: 100vh;
}

.el-tabs__header {
  margin: 0;
}

div {
  box-sizing: border-box;
}

.el-button+.el-button,
.el-checkbox.is-bordered+.el-checkbox.is-bordered {
  margin-left: 0px;
}

.el-dialog__close {
  position: relative;
  bottom: 5px;
  left: 5px;
}

.el-tabs__item {
  font-weight: 500;
  color: #ECE6F8;
}

.el-tabs__item.is-active {
  font-weight: bold;
  color: #7541EA;
}

// .el-tabs__item {
//   font-size: 18px;
// }

.my-table thead {
  font-size: 16px !important;
}

.my-table tbody {
  font-size: 16px !important;
}

.my-table tbody tr {
  padding: 15px 0;
}

.my-table thead td {
  padding: 0 10px;
}

.my-table tbody td {
  padding: 10px;
}

.img-rotate {
  transition: .5s all;
}

.img-rotate:active {
  transform: rotate(1360deg);
}

.table-tr-hover {
  cursor: pointer;
}

.el-tabs__nav-wrap::after {
  background: #212131 !important;
}

.el-tabs__active-bar {
  background: #212131 !important;
}

.el-input__inner {
  background-color: #303045;
  border: none;
}

.el-tabs__item:hover {
  color: #7541EA;
}

.table-tr-hover:hover {
  text-decoration: underline;
}
.el-input-number__decrease{
  background: rgba(102, 59, 199,0.8);
  color: #fff;
  border: none;
}
.el-input-number__increase {
  background: rgba(102, 59, 199,0.8);
  color: #fff;
  // border-left: 1px solid #DCDFE6;
  border: none;
}
.el-select-dropdown{
        background-color: #000;
        color: #fff;
        box-shadow: 0px 2px 20px #7541ea1a;
        border: 0.8px solid #0e0e33;
}
.el-select-dropdown__item.selected{
  color: #fff;
}
.el-select-dropdown__item.hover{
  background-color: #7541EA;
  color: #fff;
}
.bottom-start .popper__arrow::after{
  border-bottom-color:#000
}

.small-icon {
  width: 12px;
  height: 12px;
  width: 14px;
  height: 14px;
}

@media (min-width: 1920px) {
  .small-icon {
    width: 12px * 1.8;
    height: 12px * 1.8;
  }
}

@media (min-width: 1512px) {
  .small-icon {
    width: 12px * 1.4;
    height: 12px * 1.4;
  }
}

@media (max-width: 1512px) {
  .my-table thead {
    font-size: 14px !important;
  }

  .my-table tbody {
    font-size: 14px !important;
  }
}

@media (max-width: 1024px) {
  .my-table thead {
    font-size: 12px !important;
  }

  .my-table tbody {
    font-size: 12px !important;
  }

  // .el-tabs__item {
  //   font-size: 14px !important;
  // }
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #bbb;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.25);
}
/* 非激活窗口 */
// ::-webkit-scrollbar-thumb:window-inactive {
// }
</style>
