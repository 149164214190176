import axios from "./request";
import qs from 'qs'

/**
 * @returns
 * 公共
 */
export const publicApi = {
  // 发送邮箱验证码
  fetchSendEmail: (data) => {
    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      url: "/home/sendEmail",
      method: "POST",
      data: qs.stringify(data)
    });
  },
  // 文件上传
  fetchUploadFile: (data) => {
    return axios({
      url: "/home/uploadFile",
      method: "POST",
      data
    });
  },
};

/**
 * @returns
 * 用户
 */
export const userApi = {
  // 登录或注册
  fetchLoginOrRegister: (data) => {
    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      url: "/user/loginOrRegister",
      method: "post",
      data: qs.stringify(data)
    });
  },
  // 获取用户信息
  fetchUserInfo: () => {
    return axios({
      url: "/user/getUserInfo",
      method: "get"
    });
  },
  // 用户的事件收藏列表
  fetchUserCollectionList: () => {
    return axios({
      url: "/user/listCollection",
      method: "get"
    });
  },
  // 用户的订单列表
  fetchOrderList: () => {
    return axios({
      url: "/user/listOrder",
      method: "get"
    });
  },
  // 用户的发布事件列表
  fetchUserEventList: (query) => {
    return axios({
      url: "/user/listEvent",
      method: "get",
      params: query
    });
  },
};

/**
 * @returns
 * 用户资金
 */
export const assetsApi = {
  // 获取所有币种
  fetchAllCoin: () => {
    return axios({
      url: "/accountFunds/getAllCoin",
      method: "get"
    });
  },
  // 获取用户充值地址
  fetchDepositAddress: (query) => {
    return axios({
      url: "/accountFunds/getDepositAddress",
      method: "get",
      params: query
    });
  },
  // 根据币符号查找链集合
  fetchUserAsset: (coin) => {
    return axios({
      url: "/accountFunds/getUserAsset/" + coin,
      method: "get"
    });
  },
  // 获取单个用户账户详情
  fetchUserAssetDetails: (coin) => {
    return axios({
      url: "/accountFunds/getUserAssetDetails/" + coin,
      method: "get"
    });
  },
  // 获取用户账户
  fetchUserAssetList: () => {
    return axios({
      url: "/accountFunds/getUserAssetList",
      method: "get"
    });
  },
  // 获取提现手续费
  fetchWithdrawalsFee: (query) => {
    return axios({
      url: "/accountFunds/getWithdrawalsFee",
      method: "get",
      params: query
    });
  },
  // 提现
  fetchWithdraw: (data) => {
    return axios({
      url: "/accountFunds/withdraw",
      method: "post",
      data
    });
  },
};

/**
 * @returns
 * 记录接口
 */
export const recordApi = {
  // 获取用户资金记录
  fetchUserAssetFlowRecord: (data) => {
    return axios({
      url: "/record/getUserAssetFlowRecord",
      method: "post",
      data
    });
  },
  // 获取充值记录
  fetchRechargeRecords: (query) => {
    return axios({
      url: "/record/getRechargeRecords",
      method: "get",
      params: query
    });
  },
  // 获取提现记录
  fetchWithdrawalsRecord: (query) => {
    return axios({
      url: "/record/getWithdrawalsRecord",
      method: "get",
      params: query
    });
  },
  // 获取充值记录详情
  fetchRechargeRecordsDetails: (id) => {
    return axios({
      url: "/record/getRechargeRecordsDetails/"+ id,
      method: "get"
    });
  },
  // 获取提现记录详情
  fetchWithdrawalsRecordDetails: (id) => {
    return axios({
      url: "/record/getWithdrawalsRecordDetails/"+ id,
      method: "get"
    });
  },
};

/**
 * @returns
 * 团队接口
 */
export const teamApi = {
  // 获取团队统计
  fetchTeamStatistics: () => {
    return axios({
      url: "/team/getStatistics",
      method: "post"
    });
  },
};

/**
 * @returns
 * 事件数据
 */
export const homeApi = {
  // banner
  fetchBnnerList: () => {
    return axios({
      url: "/banner/list",
      method: "get",
    });
  },
  // 类型
  fetchCategoryListType: () => {
    return axios({
      url: "/category/listType",
      method: "get",
    });
  },
  // 标签
  fetchCategoryListTag: (query) => {
    return axios({
      url: "/category/listTag",
      method: "get",
      params: query,
    });
  },
};

/**
 * @returns
 * 事件数据
 */
export const eventApi = {
  // 事件列表
  fetchEventList: (query) => {
    return axios({
      url: "/event/list",
      method: "get",
      params: query,
    });
  },
  // 热门事件列表
  fetchEventListHot: (query) => {
    return axios({
      url: "/event/listHot",
      method: "get",
      params: query,
    });
  },
  // 事件详情
  fetchEventDetail: (eventId) => {
    return axios({
      url: "/event/" + eventId,
      method: "get",
    });
  },
  // 事件收藏
  fetchEventCollection: (data) => {
    return axios({
      url: "/event/collection",
      method: "post",
      data //: qs.stringify(data),
    });
  },
  // 事件新增
  fetchEventAdd: (data) => {
    return axios({
      url: "/event/save",
      method: "post",
      data //: qs.stringify(data),
    });
  },
  // 币列表
  fetchListWalletCoin: () => {
    return axios({
      url: "/event/listWalletCoin",
      method: "get",
    });
  },
}

/**
 * @returns
 * 订单数据 -
 */
export const orderApi = {
  // 下注
  fetchOrderConfirm: (data) => {
    return axios({
      url: "/order/confirm",
      method: "post",
      data //: qs.stringify(data),
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded'
      // },
    });
  },
  // 付款
  fetchOrderPay: (data) => {
    return axios({  
      url: "order/pay",
      method: "post",
      data
    });
  },
  // yonghu列表
  fetchOrderList: () => {
    return axios({
      url: "/order/list",
      method: "get",
    });
  },
  // 列表
  fetchOrderListByEvent: (query) => {
    return axios({
      url: "/order/listByEvent",
      method: "get",
      params: query,
    });
  },
  // 最近活动
  fetchOrderRecentActivity: (query) => {
    return axios({
      url: "/order/recentActivity",
      method: "get",
      params: query,
    });
  },
  // 本周成交量最高
  fetchOrderTopWeek: (query) => {
    return axios({
      url: "/order/topWeek",
      method: "get",
      params: query,
    });
  },
};
