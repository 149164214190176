import Vue from 'vue'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css'; // 引入Element UI样式
import VueI18n from 'vue-i18n'
import store from "./store";
import router from "./router";
import App from './App.vue'
import messages from './locale/index'
import './assets/css/tailwind.css';
import Web3 from 'web3'
let i18nConfig = {
  locale: 'zh-Hant',
  messages
}

Vue.use(ElementUI);
Vue.use(VueI18n)
const i18n = new VueI18n(i18nConfig)

import BackHeader from "@/components/BackHeader/BackHeader.vue";
Vue.component("BackHeader", BackHeader);
import Tab from "@/components/Tab/Tab.vue";
Vue.component("Tab", Tab);
Vue.prototype.Web3 = Web3

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
