<template>
  <div class="h5">
    <div class="tab-bar-con flex py-[12px] px-[5px] bg-[#010109] justify-between">
      <div class="tab-bar-con-item" v-for="(item, index) in tabBarList" @click.stop="handlePath(item)"
        :class="key === item.routerUrl ? 'activeCon' : ''" :key="index">
        <img :src="key === item.routerUrl ? item.iconActive : item.icon" />
        <span class="text-[12px] text-[#999999] font-Poppins text-center mt-[3px]"
          :class="key === item.routerUrl && 'active'">
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   key: {
  //     default: "/homeList",
  //   },
  // },
  computed: {
    footerLinkIndex() {
      return this.tabIndex
    },
    key() {
      return this.$route.path;
    },
  },
  data() {
    return {
      tabBarList: [{
        icon: require("@/assets/images/tab/home.svg"),
        iconActive: require("@/assets/images/tab/home-active.svg"),
        label: 'Home',
        routerUrl: "/homeList"
      },
      {
        icon: require("@/assets/images/tab/markets.svg"),
        iconActive: require("@/assets/images/tab/markets-active.svg"),
        label: 'Markets',
        routerUrl: "/initate"
      },
      {
        icon: require("@/assets/images/tab/chat.svg"),
        iconActive: require("@/assets/images/tab/chat-active.svg"),
        label: 'Chat',
        routerUrl: ""
      },
      {
        icon: require("@/assets/images/tab/activity.svg"),
        iconActive: require("@/assets/images/tab/activity-active.svg"),
        label: 'Activity',
        routerUrl: "/activity"
      },
      {
        icon: require("@/assets/images/tab/personal.svg"),
        iconActive: require("@/assets/images/tab/personal-active.svg"),
        label: 'Personal',
        routerUrl: "/personal"
      },]
    };
  },
  methods: {
    handlePath(item) {
      if (!item.routerUrl) {
        this.$message({
          type: "info",
          message: "暂未开发该页面"
        });
        return
      }
      if (this.key === item.routerUrl) return;
      this.$router.push({
        path: item.routerUrl,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-bar-con {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 40;
  border-top: 1px solid #6E667D;
  font-family: "PingFang SC";
}

.tab-bar-con-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
}

.active {
  color: #663BC7 !important;
}
</style>
