<template>
  <div class="container p-[20px] rounded-t-[20px] lg:max-w-[430px] lg:mx-auto lg:rounded-[18px]">
    <div class="flex items-center">
      <!-- <back-header :isModal="isDrawer" @handleClose="handleBack" /> -->
      <span class="text-[23px] leading-[29px] text-[#363638] font-medium mx-auto">Select a token</span>
    </div>
    <div class="mt-[28px]">
      <div class="flex justify-between items-center py-[6px] mt-[10px] cursor-pointer"
        style="border-bottom: 1px solid #0E0E33;" v-for="(coinsItem, coinsIndex) in drawerCoinsList" :key="coinsIndex"
        @click="handleChoose(coinsItem)" v-show="coinsItem.delFlag === '0'">
        <div class="flex items-center">
          <img class="w-[50px] h-[50px]" :src="coinsItem.coinIcon" />
          <span class="ml-[12px] text-[16px] leading-[19px] text-[#363638] font-medium">{{ coinsItem.coinName
            }}</span>
        </div>
        <div class="flex flex-col items-end">
          <!-- <span class="text-[16px] leading-[19px] text-[#363638] font-medium">{{ coinsItem.delFlag }}</span> -->
          <span class="text-[14px] leading-[16px] text-[#A9A5BC] mt-[2px]">{{ coinsItem.symbol }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { assetsApi } from "@/utils/api";

export default {
  name: "SelectToken",
  props: {
    // 是否弹框显示的页面
    // isDrawer: {
    //   type: Boolean,
    //   default: false
    // },
  },
  data() {
    return {
      drawerCoinsList: [
        // {
        //   icon: require("@/assets/images/coins/coins13.png"),
        //   lable: "USDC",
        // },
        // {
        //   icon: require("@/assets/images/coins/coins12.png"),
        //   lable: "USDT",
        // },
      ],
    };
  },
  created() {
    this.getAllCoin();
  },
  methods: {
    // 币列表
    getAllCoin() {
      assetsApi.fetchAllCoin().then((res) => {
        this.drawerCoinsList = res.data;
      });
    },
    // 选择币种
    handleChoose(item) {
      // this.$message.success("选择了" + item.coinName)
      this.$emit("handleSelectToken", item);
    },
  },
};
</script>

<style lang="scss" scoped>
// @media screen and (min-width: 1024px) {
//   .container {
//     box-shadow: 4px 0px 20px 0px #0000000F;
//   }
// }</style>
