import Vue, { KeepAlive } from "vue";
import Router from "vue-router";
// import adminRouter from "./adminRouter";
// import sysRouter from "./system";
// import wordRouter from "./word";
// import projectRouter from "./project";

Vue.use(Router);

/* Layout */
import Layout from "@/views/index.vue";

// 公共路由
export const constantRoutes = [
  {
    path: "",
    component: Layout,
    redirect: '/homeList',
    children: [
      {
        path: "/homeList",
        component: resolve => require(["@/views/homeList"], resolve),
      },
      {
        path: "/eventDetail",
        component: resolve => require(["@/views/eventDetail"], resolve),
      },
      // {
      //   path: "/betting",
      //   component: resolve => require(["@/views/betting"], resolve)
      // },
      {
        path: "/activity",
        component: resolve => require(["@/views/activity"], resolve),
      },
      // {
      //   path: "/recentActivity",
      //   component: resolve => require(["@/views/recentActivity"], resolve),
      // },
      {
        path: "/leaderboard",
        component: resolve => require(["@/views/leaderboard"], resolve),
      },
      {
        path: "/personal",
        component: resolve => require(["@/views/personal"], resolve),
      },
      {
        path: "/personalRelease",
        component: resolve => require(["@/views/personalRelease"], resolve),
      },
      {
        path: "/personalCollection",
        component: resolve => require(["@/views/personalCollection"], resolve),
      },
      {
        path: "/otherPersonal",
        component: resolve => require(["@/views/otherPersonal"], resolve),
      },
      {
        path: "/history",
        component: resolve => require(["@/views/history"], resolve),
      },
      {
        path: "/historyDetail",
        component: resolve => require(["@/views/historyDetail"], resolve),
      },
      {
        path: "/initate",
        component: resolve => require(["@/views/initate"], resolve),
      },
      {
        path: "/inviteCode",
        component: resolve => require(["@/views/inviteCode"], resolve),
      },
      // {
      //   path: "/withdraw",
      //   component: resolve => require(["@/views/withdraw"], resolve),
      // },
      // {
      //   path: "/recharge",
      //   component: resolve => require(["@/views/recharge"], resolve),
      // },
      {
        path: "/myTeam",
        component: resolve => require(["@/views/myTeam"], resolve),
      },
      {
        path: "/information",
        component: resolve => require(["@/views/information"], resolve),
      },
      {
        path: "/personalOrderRecord",
        component: resolve => require(["@/views/personalOrderRecord"], resolve),
      },
      {
        path: "/setUp",
        component: resolve => require(["@/views/setUp"], resolve),
      },
      {
        path: "/notifications",
        component: resolve => require(["@/views/notifications"], resolve),
      },
      {
        path: "/setPassword",
        component: resolve => require(["@/views/setPassword"], resolve),
      },

      {
        path: "/signIn",
        component: resolve => require(["@/views/signIn"], resolve),
      },
      {
        path: "/assets",
        component: resolve => require(["@/views/assets"], resolve),
      },
      // {
      //   path: "/selectNetwork",
      //   component: resolve => require(["@/views/selectNetwork"], resolve),
      // },
      // {
      //   path: "/selectToken",
      //   component: resolve => require(["@/views/selectToken"], resolve),
      // },
      {
        path: "/recharge",
        component: resolve => require(["@/views/recharge"], resolve),
      },
      {
        path: "/withdraw",
        component: resolve => require(["@/views/withdraw"], resolve),
      },
      {
        path: "/selectNetworkFailed",
        component: resolve => require(["@/views/selectNetworkFailed"], resolve),
      },
      {
        path: "/submitSuccess",
        component: resolve => require(["@/views/submitSuccess"], resolve),
      },
      // {
      //   path: "/assetsRechargeQr",
      //   component: resolve => require(["@/views/assetsRechargeQr"], resolve),
      // },
    ]
  },
  // {
  //   path: "/assets",
  //   component: AssetsLayout,
  //   redirect: '/assets',
  //   children: [
      // {
      //   path: "/signIn",
      //   component: resolve => require(["@/views/signIn"], resolve),
      // },
      // {
      //   path: "/assets",
      //   component: resolve => require(["@/views/wallet"], resolve),
      // },
      // {
      //   path: "/selectNetwork",
      //   component: resolve => require(["@/views/selectNetwork"], resolve),
      // },
      // {
      //   path: "/selectNetworkFailed",
      //   component: resolve => require(["@/views/selectNetworkFailed"], resolve),
      // },
      // {
      //   path: "/rechargeSuccess",
      //   component: resolve => require(["@/views/rechargeSuccess"], resolve),
      // },
      // {
      //   path: "/assetsRechargeQr",
      //   component: resolve => require(["@/views/assetsRechargeQr"], resolve),
      // },
  //   ]
  // },
  {
    path: "/searchMobile",
    component: resolve => require(["@/views/searchMobile"], resolve),
  },
  // {
  //   path: "/eventDetail",
  //   component: resolve => require(["@/components/EventDetail/EventDetail"], resolve),
  //   hidden: true
  // },
  // ...adminRouter,
  // ...sysRouter,
  // ...wordRouter,
  // ...projectRouter
];

export default new Router({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});
