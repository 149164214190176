import _copy from 'copy-to-clipboard';
import { Message } from 'element-ui'
/**
 *
 * @param text
 * @param message
 * @param message.notify 默认为 true, 如果为 true 则弹出文字提醒
 */
export function handleCopyText(text) {
  if (_copy(text)) {
    Message.success('copy successful')
  } else {
    Message('copy failed')
  }
}

export function handleTruncateText(text, frontChars = 5, backChars = 5) {
  const frontText = text.slice(0, frontChars);
  const backText = text.slice(-backChars);
  const truncatedText = `${frontText}...${backText}`;
  return truncatedText
}
