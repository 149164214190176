import {
  userApi
} from '@/utils/api'

import {
  getToken,
  setToken,
  removeToken
} from "@/utils/auth.js";

const user = {
  state: {
    inviteCode: localStorage.getItem("inviteCode") || '',
    token: getToken(),
    address: localStorage.getItem("address") || '',
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}
    // this.$store.commit("SET_ADDRESS", address);
    // userNick: "",
    // userAvatar: "",
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_ADDRESS: (state, address) => {
      state.address = address;
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
  },

  actions: {
    // 登录
    Login({
      commit
    }, userInfo) {
      // const code = userInfo.Vue.config.devtools = true
      return new Promise((resolve, reject) => {
        userApi.fetchLoginOrRegister(userInfo)
          .then((res) => {
            console.log('res', res);
            if (res.code === 200) {
              setToken(res.data.token);
              commit("SET_TOKEN", res.data.token);
            }
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({
      commit
    }) {
      return new Promise((resolve, reject) => {
        userApi.fetchUserInfo()
          .then((res) => {
            commit("SET_USERINFO", res.data);
            localStorage.setItem("userInfo", JSON.stringify(res.data))
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 退出系统
    LogOut({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        const mode = false;
        // if (mode) {
        // logout(state.token)
        //   .then(() => {
        // window.ethereum.request({
        //   "method": "wallet_revokePermissions",
        //   "params": [
        //     {
        //       "eth_accounts": {}
        //     }
        //   ]
        // });

        commit("SET_TOKEN", "");
        commit("SET_ADDRESS", "");
        localStorage.removeItem("siweData")
        console.log('清空了')
        localStorage.removeItem("address")
        localStorage.removeItem("userInfo")
        removeToken();
        resolve();
        // })
        // .catch((error) => {
        //   reject(error);
        // });
        // } else {
        //   commit("SET_TOKEN", "");
        //   removeToken();
        //   resolve();
        // }
      });
    },
  },
};

export default user;