<template>
  <div class="notifications-container lg:max-w-[430px] lg:mx-auto">
    <back-header class="h5 mb-[20px]" />
    <div class="notifications-label">
      <span>Notifications</span>
    </div>
    <div class="notifications-list">
      <div class="notifications-list-item cursor-pointer" v-for="(item, index) in notificationsOptions" :key="index"
        @click="handleChooseNotifications(item)">
        <div class="notifications-list-item-left">
          <img :src="item.avat" />
          <div class="notifications-list-item-left-info">
            <span>{{ item.name }}</span>
            <span>{{ item.content }}</span>
          </div>
        </div>
        <div class="notifications-list-item-time h5">6m</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notifications",
  data() {
    return {
      notificationsOptions: [
        {
          avat: "http://p1.music.126.net/Ls6pVy1PUNbJkEICs8-MYQ==/3240260768542190.jpg?param=50y50&quality=100",
          name: "to the liquidity provider",
          content: "Will Donald Trump win the 2024 US residential Election?",
          amout: "$988.9m Bet",
          pinglun: 86,
          id: 1,
        },
        {
          avat: "http://p1.music.126.net/Ls6pVy1PUNbJkEICs8-MYQ==/3240260768542190.jpg?param=50y50&quality=100",
          name: "to the liquidity provider",
          content: "Will Donald Trump win the 2024 US residential Election?",
          amout: "$988.9m Bet",
          pinglun: 86,
          id: 2,
        },
        {
          avat: "http://p1.music.126.net/Ls6pVy1PUNbJkEICs8-MYQ==/3240260768542190.jpg?param=50y50&quality=100",
          name: "Democratic Nominee 2044",
          content: "Will Donald Trump win the 2024 US residential Election?",
          amout: "$988.9m Bet",
          pinglun: 86,
          id: 3,
        },
      ],
    };
  },
  mounted() { },
  methods: {
    // 点击消息列表项
    handleChooseNotifications(item) {
      this.$message({
        type: "success",
        message: "点击了消息" + item.name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./pc.scss";
@import "./h5.scss";
</style>
