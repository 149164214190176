<template>
  <el-dialog :visible.sync="dialogVisible" :class="!isFullscreen && 'border-radius-dialog'" width="420px"
    :fullscreen="isFullscreen" center :close-on-click-modal="false" :before-close="handleClose">
    <div class="login-container">
      <div class="login-title">
        <span>{{ title }}</span>
      </div>

      <!-- <div class="g-signin-button" style="height: 50px; width: 100%" v-if="!isWalletLoginNotBind">
        <component class="w-full" :is="'script'" src="https://accounts.google.com/gsi/client" ansyc />
        <div id="g_id_onload" data-client_id="881808618271-a0perkf84a6e9tnep0sp5m0ap3sts4es.apps.googleusercontent.com"
          data-context="signin" data-ux_mode="popup" :data-login_uri="myOriginUrl" data-auto_prompt="false"></div>

        <div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="filled_blue"
          data-text="signin_with" data-size="large" data-locale="en-GB" data-logo_alignment="left"></div>
      </div> -->

      <div class="form-data" v-if="loginMode === 'UserName' || loginMode === 'Google'">
        <el-form label-position="top" :model="loginForm">
          <!-- <div v-if="loginMode === 'UserName'">
            <el-form-item label="Email Address">
              <el-input
                v-model="loginForm.userName"
                placeholder="Please Enter Your Email Address"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <div class="form-label">
              <span>Password</span>
              <span>Didn't receive a verification code?</span>
            </div>
            <el-form-item>
              <el-input
                type="password"
                v-model="loginForm.password"
                placeholder="Please Enter Your Verification Code..."
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </div> -->
          <!-- v-else-if="loginMode === 'Google'" -->
          <div>
            <!-- <div class="google cursor-pointer" @click="handleEmail">
              <img src="@/assets/images/login/icon5.png" />
              <div class="google-label">
                <span>Continue With Google</span>
                <span>Quick sign in</span>
              </div>
            </div> -->
            <div class="text-label">Enter Email</div>
            <div class="flex items-center Contunue w-full address-box">
              <el-form-item>
                <el-input style="width:100% ;margin-bottom: 6px;" v-model="loginForm.email" placeholder="Enter Email"
                  autocomplete="off"></el-input>
              </el-form-item>
            </div>

            <div class="text-label">Enter Code</div>
            <div class="flex justify-between items-center w-full">
              <el-form-item>
                <el-input style="width: 160%" v-model="loginForm.code" placeholder="Enter Code"
                  autocomplete="off"></el-input>
              </el-form-item>

              <el-button class="bg-[#303045] mt-[0] font-medium text-[#ece6f8]" :loading="loading"
                :disabled="isDisableLogin"
                @click="handleEmail">{{ sendStatus === "sendLoding" ? `${sendTime}S` : "Send" }}</el-button>
            </div>
          </div>
        </el-form>
        <el-button type="primary" :disabled="isDisableLogin"
          style="width: 100%;margin-top: 10px;" :loading="loading" @click="handleLogin">{{ title==='Log In' ? 'Log In' : 'Create an account' }}</el-button>
        <!-- <el-button v-if="loginMode != 'Google'" type="primary" :disabled="isDisableLogin"
          style="width: 100%;margin-top: 10px;" :loading="loading" @click="handleLogin">{{ title==='Log In' ? 'Log In' : 'Create an account' }}</el-button
          v-if="!address"> -->
        <div class="other-login" v-if="!isWalletLoginNotBind">
          <div class="or">Or</div>
          <div class="other-login-list">
            <div class="other-login-item cursor-pointer" v-for="(item, index) in loginList" :key="index"
              @click="handleCheckMode(item)">
              <div class="other-login-item-left">
                <img :src="item.icon" />
                <span>{{ item.label }}</span>
              </div>
              <div class="other-login-item-right">
                <span v-if="item.isWallet">Installed</span>
              </div>
            </div>
            <!-- <div class="other-login-item cursor-pointer">
              <div class="other-login-item-left">
                <img src="" />
                <span>Google</span>
              </div>
            </div> -->
          </div>
          <!-- 谷歌登录 -->
          <div class="g_id_signin mb-[10px]" id="g_id_signin"></div>
        </div>
      </div>
      <div class="qr-code" v-else-if="loginMode === 'Metamask' ||
        loginMode === 'Coinbase Wallet' ||
        loginMode === 'WalletConnect'
      ">
        <i class="el-icon-back cursor-pointer" @click="handleBack" />
        <img :src="qrCode" />
      </div>
      <div class="send-email" v-else-if="loginMode === 'SendEmail'">
        <div class="send-email-loading">
          <i class="el-icon-loading" />
          <div class="send-email-loading-text">
            <span>Requesting Code</span>
          </div>
          <div class="send-email-loading-tip">
            <span>Enter This Code Into The Email You Just Received</span>
          </div>
        </div>
      </div>
      <div class="send-email-done" v-else-if="loginMode === 'SendEmailDone'">
        <!-- <i class="el-icon-back cursor-pointer" @click="handleBack" /> -->
        <div class="send-email-done-label">
          Log In Using The Magic Link Sent To
        </div>
        <div class="send-email-done-addr">
          <span>{{ loginForm.email }}</span>
          <img class="cursor-pointer" src="@/assets/images/login/icon6.png" @click="handleEdit" />
        </div>
        <div class="send-email-done-code">
          <div>Then Enter This Security Code:</div>
          <span>455</span>
        </div>
        <div class="send-email-done-copy cursor-pointer" @click="handleCopy">
          <span>Copy</span>
          <img src="@/assets/images/login/icon7.png" />
        </div>
        <div class="send-email-done-footer">Secured By Guess The World</div>
      </div>

      <div class="send-email-input" v-else-if="loginMode === 'SendEmailInput'">
        <i class="el-icon-back cursor-pointer" @click="handleBack" />
        <div class="send-email-input-logo">
          <img class="cursor-pointer" src="@/assets/logo.png" />
        </div>
        <div class="send-email-input-label">
          Enter The Security Code Displayed By Polymarket
        </div>
        <div class="send-email-input-code">
          <input autofocus v-model="loginForm.code" class="Verification Code" @input="handleInput" />
        </div>
        <div class="send-email-input-footer">Secured By Guess The World</div>
      </div>
    </div>
  </el-dialog>
</template>
<script src="https://accounts.google.com/gsi/client" async defer></script>

<script>

import { copyText } from "@/utils/copy.js";
import { openWallet, initClient } from "@/utils/wallet.js";
import { modalItemClick, fetchAccountData } from "@/utils/web3.js";
import Web3 from "web3";
import { publicApi, userApi } from "@/utils/api";
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";
import * as jwt_decode from 'jwt-decode';

export default {
  name: "Login",

  props: {
    isShowLogin: {
      type: Boolean,
      default() {
        return true;
      },
    },
    propTitle: {
      type: String,
      default() {
        return "Log In";
      },
    },
  },
  data() {
    return {
      // 285841189964-biuaigibo5alttp7hksod38c5d5u8cf0.apps.googleusercontent.com
      // 881808618271-a0perkf84a6e9tnep0sp5m0ap3sts4es.apps.googleusercontent.com

      GOOGLE_CLIENT_ID:"285841189964-biuaigibo5alttp7hksod38c5d5u8cf0.apps.googleusercontent.com",

      form: {},
      initWalletSuccess: false,
      dialogVisible: this.isShowLogin,
      isFullscreen: false,

      loading: false,

      title: this.propTitle,

      loginForm: {
        email: "",
        code: "",
        inviteCode: this.$store.getters.inviteCode,
        credential: "",
      },

      // 是否钱包登录但是没有绑定邮箱
      isWalletLoginNotBind: false,

      loginMode: "UserName",

      qrCode: "",

      loginList: [
        {
          icon: require("@/assets/images/login/icon1.png"),
          label: "Metamask",
          qrCode: require("@/assets/images/login/code1.png"),
          isWallet: false,
        },
        {
          icon: require("@/assets/images/login/icon2.png"),
          label: "Coinbase Wallet",
          qrCode: require("@/assets/images/login/code2.png"),
          isWallet: false,
        },
        {
          icon: require("@/assets/images/login/icon3.png"),
          label: "WalletConnect",
          qrCode: require("@/assets/images/login/code3.png"),
        },
        // {
        //   icon: require("@/assets/images/login/icon4.png"),
        //   label: "Google",
        // },
      ],
      sendStatus: "",
      sendTime: 60,
      timer: null,
    };
  },
  computed: {
    myOriginUrl() {
      return document.location.origin;
    },

    isDisableLogin() {
      // if (this.loginMode === "UserName") {
      //   return (
      //     !((this.loginForm.userName ?? "") !== "") ||
      //     !((this.loginForm.password ?? "") !== "")
      //   );
      // } else if (this.loginMode === "Google") {
      return !((this.loginForm.email ?? "") !== "");
      // } else {
      //   return false;
      // }
    },
    // address() {
    //   return this.$store.getters.address;
    // },
  },
  
  mounted() {
    this.$nextTick(()=>{
      console.log('window.google.accounts', window.google);
      if(!window.google.accounts) return
      window.google.accounts.id.initialize({
        // 主要就是填写client_id
        client_id: this.GOOGLE_CLIENT_ID,
        auto_select: false,
        callback: this.handleCredentialResponse,
      });
      // 设置按钮的样式等
      window.google.accounts.id.renderButton(
        document.getElementById("g_id_signin"),
        {
          theme: "filled_blue",
          size: "large",
          background: "#303045",
          // width:'100%',
          type: "standard",
          text: "Sign in with Google",
          locale: "en-GB",
        }
      );
    })
  
    //  检测钱包是否安装 metamask coinbase
    if (window.ethereum) {
      this.loginList[0].isWallet = true;
      console.log("window.ethereum", window.ethereum);
      if (
        window.ethereum.providers &&
        window.ethereum.providers[0]?.providers
      ) {
        for (
          var i = 0;
          i < window.ethereum.providers[0].providers.length;
          i++
        ) {
          if (window.ethereum.providers[0].providers[i].isCoinbaseWallet) {
            this.loginList[1].isWallet =
              window.ethereum.providers[0].providers[i].isCoinbaseWallet;
          }
          if (window.ethereum.providers[0].providers[i].isMetaMask) {
            this.loginList[0].isWallet =
              window.ethereum.providers[0].providers[i].isMetaMask;
          }
        }
      }
    }
    this.initWallet();
    this.updateScreenResolution();
    window.addEventListener("resize", this.updateScreenResolution);
    this.loginMode = this.propTitle === "Log In" ? "UserName" : "Google";
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenResolution);
  },
  methods: {
    async handleCredentialResponse(response) {
        // 获取回调响应的凭证数据 然后拿这个凭证给后台，jwt进行解析获取登录信息
      // await api.googleLogin(code);
      console.log("credential response:", response.credential);
      // const decode = jwt_decode.jwtDecode(response.credential); // 解析
      // id = profile.sub; email = profile.email;  需要什么取什么，然后传给后端验证
      // console.log("GOOGLE response:", decode);
      this.loginForm.credential = response.credential;
      this.handleLogin();
    },

    //
    async initWallet() {
      // 初始化walletconnect  wallet钱包
      await initClient();
    },

    // 获取当前屏幕分辨率
    updateScreenResolution() {
      this.isFullscreen = window.innerWidth <= 1024;
    },

    handleClose() {
      this.dialogVisible = false;
      this.$emit("update:isShowLogin", false);
    },

   
    async initWallet() {
      // 初始化wallet钱包
      console.log("init wallet ...");
      this.initWalletSuccess = false;
      await initClient();
      this.initWalletSuccess = true;
      console.log("init wallet success");
    },
    // 切换登录方式
    async handleCheckMode(item) {
      // this.loginMode = item.label;
      const loading = this.$loading({
        lock: true,
        text: "Please operate in the wallet window",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // this.handleClose();
      this.dialogVisible = false;
      console.log("result");
      let walletLoginResult;
      // Metamask钱包
      if (item.label == "Metamask") {
        walletLoginResult = await modalItemClick("injected");
      }
      // // Coinbase Wallet
      if (item.label == "Coinbase Wallet") {
        // const provider = await modalItemClick("walletlink");
        walletLoginResult = await modalItemClick("walletlink");
        //  await fetchAccountData(provider);
      }
      // WalletConnect
      if (item.label == "WalletConnect") {
        // 使用walletconnect钱包
        walletLoginResult = await openWallet(loading).bind(this);
      }
      console.log(
        "walletLoginResult",
        walletLoginResult,
        this.address,
        this.$store.getters.address
      );
      loading.close();
      if (walletLoginResult) {
        Object.keys(this.loginForm).forEach((key) => {
          if (key !== "inviteCode") this.loginForm[key] = "";
        });
        this.handleLogin();
      } else {
        this.dialogVisible = true;
      }
      // console.log("关闭吗", item);
      // 点了钱包关闭弹窗
      // this.handleClose();
      return;
    },

     // 账号密码登录
     handleLogin() {
      const loading = this.$loading({
        lock: true,
        text: "Login···",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$store
        .dispatch("Login", this.loginForm)
        .then((res) => {
          console.log("res", res);
          if (res.code === 200) {
              // 判断当前用户是否已拉取完user_info信息
              loading.close();
              this.$message.success("Login Success");
              this.handleClose();
              this.$store.dispatch('GetInfo')
          } // 钱包登录但是没有绑定邮箱
          else if (res.code === 20003) {
            this.isWalletLoginNotBind = true;
            this.dialogVisible = true;
          }
        }).catch(_ => {
          loading.close()
        })
       
    },
    stopCountdown(){
      clearInterval(this.timer)
      this.sendStatus = ""
    },

    // 点击邮箱登录
    handleEmail() {
      // this.loginMode = "SendEmailInput";
      // this.title = null;
      // return
      // this.loginMode = "SendEmail";
      // this.title = `Magic`;
      this.sendStatus ="sendLoding" 
      this.timer = setInterval(() => {
        if (this.sendTime > 0) {
          this.sendTime--
        } else {
          this.stopCountdown()
        }
      }, 1000)
      publicApi
        .fetchSendEmail({
          email: this.loginForm.email,
          codeType: "login_register",
        })
        .then((res) => {
          console.log("res", res);
          // this.loginMode = "SendEmailDone";
          // this.title = `Check Your Email`;
          // this.loginMode = "SendEmailInput";
          // this.title = null;
          // this.sendStatus = ""
        })
        .catch((err) => {
          this.handleBack();
        });
    },

    // 编辑邮箱
    handleEdit() {
      this.loginMode = "Google";
      this.title = this.propTitle;
    },

    // 复制验证码
    handleCopy() {
      handleCopyText(455);
      this.loginMode = "SendEmailInput";
      this.title = null;
    },

    // 监听输入
    handleInput(event) {
      if (event.target.value.length === 6) {
        this.handleLogin();
      }
    },

    handleBack() {
      this.qrCode = null;
      this.loginMode = "UserName";
      this.title = this.propTitle;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./pc.scss";
@import "./h5.scss";

.g_id_signin {
  margin-top: 20px;
  // display: flex;
  justify-content: center;

  .S9gUrf-YoZ4jf {
    .nsm7Bb-HzV7m-LgbsSe {
      background: #000 !important;
    }
  }

}

#g_id_signin>div>div>div[role="button"] {
  // height: 48px;
  background-color: #000 !important;
}

#g_id_signin>div>div>div[role="button"]>div>div>div {
  width: 30px !important;
  height: 30px !important;
  /*可以修改logo的width、height、margin等*/
}

.text-label {
  color: #fff;
  font-family: "PingFang SC";
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 6px;
  margin-left: 4px;
}

:deep(.Contunue .el-button) {
  margin-top: 0 !important;
}

:deep(.el-dialog) {
  background: #241e31;
  box-shadow: 0px 2px 6px #0000000f;
}

:deep(.el-input__inner, .el-input__inner:focus, .el-input__inner:hover) {
  background: #020216;
  border-radius: 8px;
  border: 1px solid #7541ea;
  color: #fff;
  display: block;
}

:deep(.el-input__inner:focus) {
  border: 1px solid #7541ea;
}

:deep(.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover) {
  background: #303045;
  color: var(--first-title-color);
}

.el-button.is-disabled:hover {
  background: #303045;
}

:deep(.el-button) {
  border: none;
}

.address-box {
  .el-form-item {
    width: 100%;
  }
}

.border-radius-dialog :deep(.el-dialog) {
  border-radius: 15px;
}
</style>
