<template>
  <div>
    <div class="flex items-center justify-between">
      <i class="el-icon-arrow-left text-[#ece6f8] font-bold cursor-pointer" @click="handleBack" />
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BackHeader',
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    // 返回
    handleBack() {
      if (this.isModal) {
        this.$emit("handleClose")
      } else {
        this.$router.go(-1);
      }
    },
  }
}
</script>
<style lang="scss" scoped></style>
