import Web3 from "web3";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
// import {
//   MetaMaskSDK
// } from "@metamask/sdk";
import {
  SiweMessage,
  generateNonce
} from "siwe";
//获取各个钱包的provider
import {
  utils,
  ethers
} from 'ethers';
import store from "@/store";


import detectEthereumProvider from '@metamask/detect-provider';
// const APP_NAME = "coinbase-wallet-example";
// const APP_LOGO_URL = "./coinbase-logo.png";
const DEFAULT_ETH_JSONRPC_URL =
  "https://mainnet.infura.io/v3/241c9f83cdfe46e095aa3cdd3a11028d"; // Replace with your own Infura.io project
const DEFAULT_CHAIN_ID = 3; // 1=Ethereum (mainnet), 3=Ropsten, 5=Gorli
// const DEFAULT_ETHEREUM_CHAIN_ID = "0x3"; // Should match DEFAULT_CHAIN_ID above, but with leading 0x

const modalItemClick = async (name) => {
  // let provider;
  // let web3;
  // let ethereum;
  // let accounts;
  // metamask 实例
  // let MMSDK = null;
  let siwe = {
    chainId: 0x38,
    nonce: "",
    address: "",
    domain: window.location.host,
    uri: document.location.origin,
    signature: "",
    statement: "Welcome to Guess World",
    version: "1",
  };
  // coinbase 实例
  // let walletLink = null;

  console.log('this.$store', store);
  if (name === "injected") {
    console.log("injected");

    try {
      // 检测 MetaMask 插件
      const providerRes = await detectEthereumProvider();

      if (!providerRes) {
        console.error('MetaMask not detected');
        return;
      }
      // store.commit("SET_ADDRESS", 666);

      // 请求用户连接钱包
      await providerRes.request({
        method: 'eth_requestAccounts'
      });

      // 创建以太坊提供者和签名器
      const ethersProvider = new ethers.providers.Web3Provider(providerRes);
      const signer = ethersProvider.getSigner();

      // 获取用户地址
      const address = await signer.getAddress();
      siwe.address = address
      console.log('Address:', address);
      siwe.nonce = generateNonce();
      siwe = new SiweMessage({
        domain: document.location.host,
        address: siwe.address,
        chainId: siwe.chainId,
        uri: document.location.origin,
        version: "1",
        statement: "Welcome!",
        nonce: siwe.nonce,
      });
      // 生成签名
      siwe.signature = await providerRes
        .request({
          method: "personal_sign",
          params: [siwe.prepareMessage(), siwe.address],
        })
        console.log('Signature:', siwe.signature);
        localStorage.setItem("siweData", JSON.stringify(siwe))
        localStorage.setItem("address", siwe.address)
        store.commit("SET_ADDRESS", siwe.address);
    } catch (error) {
      console.error('Error during MetaMask login:', error);
    }

  }

  if (name == "walletlink") {
    try {
      // 检测 coinbase 插件
      const wallet =  new CoinbaseWalletSDK({
        appName: 'MyApp',
        appLogoUrl: 'https://example.com/logo.png',
        darkMode: false
      });

       // 创建以太坊提供者
       const ethereum = wallet.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID); // 替换为你的 RPC URL 和网络 ID
       const web3Provider = new ethers.providers.Web3Provider(ethereum);
       const signer = web3Provider.getSigner();
          // 请求用户连接钱包
      await ethereum.request({
        method: 'eth_requestAccounts'
      });

      // 创建以太坊提供者和签名器
      // const ethersProvider = new ethers.providers.Web3Provider(provider);
      // 获取用户地址
      const address = await signer.getAddress();
      siwe.address = address
      console.log('Address:', address);
      siwe.nonce = generateNonce();
      siwe = new SiweMessage({
        domain: document.location.host,
        address: siwe.address,
        chainId: siwe.chainId,
        uri: document.location.origin,
        version: "1",
        statement: "Welcome!",
        nonce: siwe.nonce,
      });
      siwe.signature = await ethereum
        .request({
          method: "personal_sign",
          params: [siwe.prepareMessage(), siwe.address],
        })
        console.log('Signature:', siwe.signature);
        localStorage.setItem("siweData", JSON.stringify(siwe))
        localStorage.setItem("address", siwe.address)
        store.commit("SET_ADDRESS", siwe.address);
    } catch (error) {
      console.error('Error during coinbase login:', error);
    }
  }

  // console.log('providerproviderprovider', provider, siwe);
  // if (provider) {
  //   try {
  //     await provider.enable();
  //   } catch {
  //     return;
  //   }
  //   return provider;
  // }
  // setTimeout(() => {
    return siwe.address;
};

const fetchAccountData = async (provider) => {
  // store.commit('setWalletModal', {
  //     showWallet: false
  // })

  const web3 = new Web3(provider);

  // Get connected chain id from Ethereum node
  const chainId = await web3.eth.getChainId();
  if (chainId != process.env.VUE_APP_ETH_CHAIN_ID) {
    //判断是否ETH主链
    //不是主链，你要干点啥都行
  }
  const accounts = await web3.eth.getAccounts();
  return accounts[0]
  //钱包地址存入VUEX
  // store.commit('setWalletAddress', {
  //     address: accounts[0]
  // })
  // console.log(accounts[0])

  // if (provider.on) {
  //     // 监听地址变更d
  //     provider.on('accountsChanged', (accounts) => {
  //         // store.commit('setWalletAddress', {
  //         //     address: accounts[0]
  //         // })
  //         console.log(accounts[0])

  //         // window.location.reload()

  //     })
  //     // 监听网络变更
  //     provider.on('chainChanged', (chainId) => {
  //         window.location.reload()
  //     })
  // }
  // 获取钱包余额
  // const rowResolvers = accounts.map(async (address) => {
  //   store.commit('setWalletBalance', { balance: await web3.eth.getBalance(address) })
  //   const ethBalance = web3.utils.fromWei(store.state.wallet.balance, 'ether')
  //   // console.log('ethBalance: ', ethBalance)
  //   const humanFriendlyBalance = parseFloat(ethBalance).toFixed(4)
  //   // console.log('humanFriendlyBalance: ', humanFriendlyBalance)
  // })
  // await Promise.all(rowResolvers)
};


const toEIP55 = (address) => {
  let checksumAddress;
  checksumAddress = utils.getAddress(address);
  return checksumAddress;
}


export {
  modalItemClick,
  fetchAccountData
};