<template>
  <div class="header">
    <div class="header-container">
      <div class="header-left">
        <img
          src="@/assets/logo.png"
          class="logo cursor-pointer"
          @click="handlePath({ routerUrl: '/homeList' })"
        />
        <div
          class="logo-title cursor-pointer"
          @click="handlePath({ routerUrl: '/homeList' })"
        >
          Guess world
        </div>
        <el-input
          class="pc"
          placeholder="Search Markets"
          v-model="searchValue"
          prefix-icon="el-icon-search"
        />
      </div>
      <div class="header-right">
        <div class="nav-list pc">
          <div
            class="nav-item cursor-pointer"
            v-for="navItem in navList"
            :key="navItem.title"
            @click="handleNav(navItem)"
          >
            <img :src="navItem.imageUrl" style="width: 20px; height: 20px" />
            <span>{{ navItem.title }}</span>
          </div>
        </div>
        <div class="logined" v-if="isLogin">
          <div class="login-amout pc">
            <div
              class="login-amout-label cursor-pointer"
              v-for="item in loginInfo.amout"
              :key="item.amout"
              @click="handlePath(item)"
            >
              <span>{{ item.value }}</span>
              <span>{{ item.label }}</span>
            </div>
          </div>
          <div class="deposit cursor-pointer pc" @click="handleDeposit">
            <span>Deposit</span>
          </div>
          <div class="h5">
            <div
              class="icon38 flex cursor-pointer"
              @click="handlePath({ routerUrl: '/notifications' })"
            >
              <img src="@/assets/images/icon/icon38.svg" />
            </div>
          </div>
          <div class="pc">
            <div class="icon38 cursor-pointer" @click="handleShowModal(1)">
              <img src="@/assets/images/icon/icon38.svg" />
            </div>
          </div>
          <div class="avat cursor-pointer" @click="handleShowModal(2)">
            <img :src="loginInfo.avat" />
          </div>
        </div>
        <div class="no-login" v-else>
          <div class="login">
            <div class="log-in cursor-pointer" @click="handleLogin('Log In')">
              Log In
            </div>
            <div class="sign-up cursor-pointer" @click="handleLogin('Sign Up')">
              Sign Up
            </div>
          </div>
          <div class="more pc cursor-pointer" @click="handleShowModal(2)">
            <img src="@/assets/images/icon/icon1.svg" class="small-icon" />
          </div>
        </div>
      </div>
    </div>
    <div class="tabs">
      <el-tabs v-model="tabActive" @tab-click="handleTabClick">
        <el-tab-pane
          :label="tabItem.categoryLabel"
          :name="tabItem.categoryValue"
          v-for="tabItem in tabsList"
          :key="tabItem.categoryValue"
        />
      </el-tabs>
    </div>
    <div class="modal" v-show="isShowModalType" @click="handleCloseModal">
      <div class="notifications" v-if="isShowModalType === 1">
        <notifications />
        <!-- <back-header :isModal="true" @handleClose="handleCloseModal" class="h5" />
        <div class="notifications-label">
          <span>Notifications</span>
        </div>
        <div class="notifications-list">
          <div class="notifications-list-item cursor-pointer" v-for="(item, index) in notificationsOptions" :key="index"
            @click="handleChooseNotifications(item)">
            <div class="notifications-list-item-left">
              <img :src="item.avat" />
              <div class="notifications-list-item-left-info">
                <span>{{ item.name }}</span>
                <span>{{ item.content }}</span>
              </div>
            </div>
            <div class="notifications-list-item-time h5">6m</div>
          </div>
        </div> -->
      </div>
      <div class="avat-menu" v-else-if="isShowModalType === 2">
        <div class="avat-menu-header" v-if="isLogin">
          <img
            class="avat-menu-header-avat cursor-pointer"
            :src="loginInfo.avat"
            @click="handlePath(loginInfo)"
          />
          <div class="avat-menu-header-info">
            <div
              class="avat-menu-header-info-name text-underline"
              @click="handlePath(loginInfo)"
            >
              {{ loginInfo.name }}
            </div>
            <div class="avat-menu-header-info-address" v-if="loginInfo.address">
              <span>{{ loginInfo.address }}</span>
              <i
                class="el-icon-copy-document cursor-pointer"
                @click="handleCopy(loginInfo.address)"
              />
            </div>
          </div>
        </div>
        <div class="avat-menu-list">
          <div
            class="avat-menu-list-item"
            v-for="(menuItem, menuIndex) in menuOptions"
            :key="menuIndex"
            @click="handleChooseMenu(menuItem)"
            v-show="
              isLogin
                ? menuIndex > 1
                : (menuIndex < 2 || menuIndex > 4) &&
                  menuIndex !== menuOptions.length - 1
            "
          >
            <span>{{ menuItem.label }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleCopyText } from "@/utils/copy.js";
import Notifications from "@/views/notifications";
import { homeApi } from "@/utils/api";

export default {
  name: "Header",
  props: {},
  components: { Notifications },
  data() {
    return {
      searchValue: "",

      navList: [
        {
          imageUrl: require("@/assets/images/icon/icon83.svg"),
          title: "Release",
          routerUrl: "/initate",
        },
        {
          imageUrl: require("@/assets/images/icon/icon6.svg"),
          title: "Markets",
          routerUrl: "/homeList",
        },
        {
          imageUrl: require("@/assets/images/icon/icon7.svg"),
          title: "Election",
          routerUrl: "",
        },
        {
          imageUrl: require("@/assets/images/icon/icon8.svg"),
          title: "Activity",
          routerUrl: "/activity",
        },
        {
          imageUrl: require("@/assets/images/icon/icon9.svg"),
          title: "Ranks",
          routerUrl: "/leaderboard",
        },
      ],

      // loginInfo: {},

      // 1.消息列表 2.头像菜单
      isShowModalType: null,
      // isChooseNotifications: false,
      notificationsOptions: [
        {
          avat: "http://p1.music.126.net/Ls6pVy1PUNbJkEICs8-MYQ==/3240260768542190.jpg?param=50y50&quality=100",
          name: "to the liquidity provider",
          content: "Will Donald Trump win the 2024 US residential Election?",
          amout: "$988.9m Bet",
          pinglun: 86,
          id: 1,
        },
        {
          avat: "http://p1.music.126.net/Ls6pVy1PUNbJkEICs8-MYQ==/3240260768542190.jpg?param=50y50&quality=100",
          name: "to the liquidity provider",
          content: "Will Donald Trump win the 2024 US residential Election?",
          amout: "$988.9m Bet",
          pinglun: 86,
          id: 2,
        },
        {
          avat: "http://p1.music.126.net/Ls6pVy1PUNbJkEICs8-MYQ==/3240260768542190.jpg?param=50y50&quality=100",
          name: "Democratic Nominee 2044",
          content: "Will Donald Trump win the 2024 US residential Election?",
          amout: "$988.9m Bet",
          pinglun: 86,
          id: 3,
        },
      ],
      // isChooseMenu: false,
      menuOptions: [
        {
          label: "Sign Up",
          routerUrl: "",
        },
        {
          label: "Log In",
          routerUrl: "",
        },
        {
          label: "Profile",
          routerUrl: "",
        },
        {
          label: "Settings",
          routerUrl: "/setUp",
        },
        {
          label: "Watchlist",
          routerUrl: "",
        },
        {
          label: "Elections",
          routerUrl: "",
        },
        {
          label: "Rewards",
          routerUrl: "",
        },
        {
          label: "Learn",
          routerUrl: "",
        },
        {
          label: "Documentation",
          routerUrl: "",
        },
        {
          label: "Terms Of Use",
          routerUrl: "https://word.guesstheworld.net/GTW_Terms_of_Use.pdf",
        },
        {
          label: "Logout",
        },
      ],

      tabActive: this.$store.getters.homeTabActive.categoryValue,
      tabsList: [],
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters.token;
    },
    loginInfo() {
      return {
        avat: this.$store.getters.userInfo.avatar,
        name: this.$store.getters.userInfo.userName,
        address: this.$store.getters.userInfo.walletAddress,
        routerUrl: "/personal",
        amout: [
          {
            label: "Portfolio",
            value: "0.00",
            routerUrl: "/personalOrderRecord",
          },
        ],
      };
    },
    key() {
      return this.$route.path;
    },
  },
  created() {
    this.getTabsList();
  },
  methods: {
    getTabsList() {
      homeApi
        .fetchCategoryListType()
        .then((res) => {
          this.tabsList = res.data;
          this.tabsList.unshift({
            categoryId: "",
            categoryLabel: "All",
            categoryParentId: "",
            categorySort: 0,
            categoryValue: "All",
          });
          this.tabActive = this.tabsList[0].categoryValue;
          this.$store.commit("setHomeTabActive", this.tabsList[0]);
          // this.$emit('handleHomeTabActive', this.tabsList[0])
          this.$emit("handleLoading");
        })
        .catch(() => {
          this.tabsList = [];
          this.tabActive = "";
          this.$store.commit("setHomeTabActive", {});
          // this.$emit('handleHomeTabActive', {})
          this.$emit("handleLoading");
        });
    },
    // 点击排行
    handleNav(navItem) {
      this.handlePath(navItem);
      this.tabActive = "";
      this.$store.commit("setHomeTabActive", {});
      this.$emit("handleHomeTabActive", {});
    },

    // 点击登录
    handleLogin(title) {
      this.$emit("handleLogin", title);
    },

    // 显示下拉框
    handleCloseModal() {
      this.isShowModalType = null;
    },

    // 1.消息  2.头像
    handleShowModal(isShowModalType) {
      this.isShowModalType = isShowModalType;
    },

    // 点击菜单
    handleChooseMenu(menuItem) {
      if (menuItem.label === "Sign Up" || menuItem.label === "Log In") {
        this.handleLogin(menuItem.label);
      } else if (menuItem.label === "Logout") {
        this.$confirm("Are you sure to exit？", "tip", {
          confirmButtonText: "comfirm",
          cancelButtonText: "cancel",
          type: "warning",
        })
          .then(() => {
            this.$store.dispatch("LogOut").then(() => {});
          })
          .catch(() => {});
      } else if (menuItem.label === "Terms Of Use") {
        window.open(menuItem.routerUrl);
      } else {
        this.handlePath(menuItem);
      }
    },

    handlePath(item) {
      if (!item.routerUrl) {
        this.$message({
          type: "info",
          message: "暂未开发该页面",
        });
        return;
      }
      if (this.key === item.routerUrl) return;
      this.$router.push({
        path: item.routerUrl,
      });
    },

    handleDeposit() {
      this.$emit("handleDeposit")
    },

    // 复制地址
    handleCopy(textToCopy) {
      handleCopyText(textToCopy);
    },

    // tab切换事件
    handleTabClick(tabEvent) {
      // console.log(tabEvent, this.key);
      // this.tabActive = this.tabsList[0].categoryValue;
      // return
      this.$store.commit("setHomeTabActive", this.tabsList[tabEvent.index]);
      // this.$emit('handleHomeTabActive', { homeTabActive: this.tabsList[tabEvent.index] });
      if (this.key !== "/homeList") {
        this.$router.push({
          path: "/homeList",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./pc.scss";
@import "./h5.scss";

:deep(.el-tabs .el-tabs__header) {
  padding: 10px;
  // background-color: #ffffff;
  background: #212131;
  color: #ece6f8;
}
</style>
