import axios from "axios";
import {
  Notification,
  // MessageBox,
  Message
} from "element-ui";
// import store from "@/store";
import router from "@/router";
import {
  getToken
} from "@/utils/auth";

axios.defaults.headers['Content-Type'] = 'application/json'

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 3000000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    const isLogin = config.url.indexOf("/user/loginOrRegister") !== -1;
    const siweData = JSON.parse(localStorage.getItem("siweData"));
    if (isLogin && siweData) {
      console.log("siweData", siweData);
      config.headers["x-nonce"] = siweData.nonce || "";
      config.headers["x-siwe-signature"] = siweData.signature || "";
      config.headers["x-siwe-domain"] = siweData.domain || "";
      config.headers["x-siwe-address"] = siweData.address || "";
      config.headers["x-siwe-statement"] = siweData.statement || "";
      config.headers["x-siwe-uri"] = siweData.uri || "";
      config.headers["x-siwe-version"] = siweData.version || "";
      config.headers["x-siwe-chainId"] = siweData.chainId || "";
      config.headers["x-siwe-nonce"] = siweData.nonce || "";
      config.headers["x-siwe-issuedAt"] = siweData.issuedAt || "";
      config.headers["x-siwe-expirationTime"] =
        siweData.expirationTime || "";
      config.headers["x-siwe-notBefore"] = siweData.notBefore || "";
      config.headers["x-siwe-requestId"] = siweData.requestId || "";
      config.headers["x-siwe-resources"] = siweData.resources || "";
    }
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    const msg = res.data.msg || "Error";
    if (code == 504) {
      Message({
        message: "请求超时，请稍后再试",
        type: "error",
        duration: 5 * 1000,
      });
      return;
    }
    const isLogin = res.config.url.indexOf("/user/loginOrRegister") !== -1;
    if (
      (isLogin &&
        code == 20003)
    ) {
      return res.data;
    }
    // 获取错误信息
    if (code === 20011) {
      // MessageBox.confirm(
      //   "登录状态已过期，您可以继续留在该页面，或者重新登录",
      //   "系统提示", {
      //   confirmButtonText: "重新登录",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }
      // ).then(() => {
      //   store.dispatch("LogOut").then(() => {
      //     location.href = "/index";
      //   });
      // });
      Notification.error({
        title: msg,
      });
      router.replace('/homeList')
      // location.href = "/homeList";
      // store.dispatch("LogOut").then(() => { });
    } else if (code === 500) {
      Message({
        message: msg,
        type: "error",
      });
      return Promise.reject(new Error(msg));
    } else if (code !== 200) {
      if (msg == "Network Error") {
        Notification.error({
          title: "Network Error",
        });
      } else {
        Notification.error({
          title: msg,
        });
      }

      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    console.log(error);
    console.log("err" + error);
    Message({
      message: "系统接口请求超时，请稍后再试",
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;