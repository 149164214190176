<template>
    <div class="modal" v-if="isShowModal" @click="handleClose">
        <div class="w-[100%] h-[800px] bg-[#fff] rounded-t-[20px] overflow-y-auto lg:max-w-[430px] lg:mx-auto"
            style="box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.2);">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Drawer',
    props: {
        isShowDrawer: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isShowModal: this.isShowDrawer,
        };
    },
    created() { },
    methods: {
        handleClose() {
            this.isShowModal = false
            this.$emit("update:isShowDrawer", false)
            console.log('关闭', this.isShowModal);
        }
    }
};
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    outline: 0;
    backface-visibility: hidden;
    perspective: 2000rpx;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-end;
}

@media (max-width: 1024px) {
    .modal {
        width: 100%;
    }
}
</style>